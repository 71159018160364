import LinkContainer from '@Components/generics/LinkContainer'
import SectionTitle from '@Components/generics/SectionTitle'
import CardItem from '@Components/global/CardItem'
import VideoItem from '@Components/global/VideoItem'
import useTitle from '@Hooks/useTitle'
import useWindowSize from '@Hooks/useWindowSize'
import HeartIcon from '@mui/icons-material/Favorite'
import { Button, Card, CardContent, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { pink } from '@mui/material/colors'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { selectFirebaseUser } from '@State/firebase/firebaseUserReducer'
import { useAppSelector } from '@State/hooks'
import Head from 'next/head'
import React from 'react'

const CreatorsPage = () => {
  const firebaseUser = useAppSelector(selectFirebaseUser)

  const titleString = useTitle()
  const size = useWindowSize()

  const headerTitle = 'Your biggest moments immortalized'

  const mainTitle = `Digital collectibles for a digital world`
  const mainText = `Your fans <span style="font-weight: bold; color: ${pink[500]}">love</span> digital collectibles. From Mythics in Magic 
  the Gathering, to Legendaries in NBA Top Shot, to Ultimate 
  champions in League of Legends, we all want to own digital items for the 
  things we love.`
  const mainBottom = `Your fans love what you create, give them an opportunity to own something special!`

  const creationsTitle = `Introducing Creations™`
  const creationsText = `Creations are the way to immortalize your most treasured works and important moments as a creator. A beloved illustration, a stream clip of an incredible frag, a viral photograph - Creations let your fans own beautiful digital items celebrating your work!`
  const creationItems = [
    {
      image: '/assets/customize.png',
      title: `Fully customizable`,
      text: `Easily make dazzling effects which enhance the desirability of your special items`,
    },
    {
      image: '/assets/touch.png',
      title: `Interactive`,
      text: `Tilt and zoom your Creations to view the shiny holographic foil effects`,
    },
    {
      image: '/assets/video.png',
      title: `Image & video`,
      text: `We play nice with digital art, illustrations, photographs, Youtube/Twitch clips, and more!`,
    },
  ]

  const aboutTitle = `About CreatorBay`
  const aboutText = `CreatorBay was founded with one goal in mind:<br /><br /><span style="font-weight: bold; color: white">"Democratize digital collectibles."</span><br /><br />All creators, big and small, should be able to provide their fans with beautiful collectibles that they can feel proud of. It is no longer a space reserved for billion dollar companies and shady salesmen, it is a space for everyone!`
  const aboutLink = `Learn more about our mission and history`
  const aboutItems = [
    {
      title: `Trust`,
      text: `We know that the world of digital items can feel like the wild west, it's why CreatorBay was founded. We are a US company, we are not anonymous, and we have a track record proving our trustworthiness.`,
    },
    {
      title: `Partnership`,
      text: `We see each creator as a partner, it's why we have zero up front fees and instead rely on a revenue share model for each project. We are invested in making sure you can deliver the highest quality digital items to your fanbase and that there will be a thriving marketplace for buying, selling, and trading Creations.`,
    },
    {
      title: `Service`,
      text: `&quot;All support tickets go straight to my inbox. If you have any questions, concerns, ideas, or just want to chat about your project, I'd love to hear from you!&quot; Blake Kimball, Founder`,
    },
  ]

  const supportTitle = `The Creator Fund`
  const supportText = `At CreatorBay, we're committed to fostering a strong and supportive community of content creators. Our goal is to take 10% of our profits and reinvest them into small and mid-size creators, providing funds which can be used for purchasing much needed equipment, or simply to help with living expenses.`

  const startTitle = `Let's get started!`
  const startText = `If you have preexisting art, photos, video clips, or other content, you could have a pack ready for publishing in under an hour! Give your fans what they want and apply now!`

  const ApplyContainer = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        {firebaseUser && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{ width: '100%', maxWidth: '250px', m: 1 }}
            href="/creators/apply"
          >
            Apply now
          </Button>
        )}
        {!firebaseUser && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{ width: '100%', maxWidth: '250px', m: 1 }}
            href="/sign-in?r=/creators/apply"
          >
            Apply now
          </Button>
        )}
      </Box>
    )
  }

  const willok = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-corners-01.webp',
    color1: '#f4ccff',
    color2: '#abbfff',
    description: '',
    video: '/assets/examples/willok.mp4',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/bokkah1.jpg',
    title: 'Willokeys',
  }
  const exampleSinger = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-rays-bottom-up-01.webp',
    color1: '#f4ccff',
    color2: '#abbfff',
    description: '',
    image: '/assets/examples/singer3.jpg',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/bokkah1.jpg',
    title: 'Singer',
  }
  const exampleTree = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/clouds-particles-rays-bright-diagonal-01.webp',
    color1: '#c6e6ff',
    color2: '#f2afff',
    description: '',
    image: '/assets/examples/tree.jpg',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture: '',
    title: 'Tree',
  }
  const exampleSculpture = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/zoom-lines-particles-01.webp',
    color1: '#fff1bf',
    color2: '#f8beff',
    description: '',
    image: '/assets/examples/sculpture.jpg',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise5b.jpg',
    title: 'Sculpture',
  }
  const exampleGraffiti = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-up-01.webp',
    color1: '#fff39e',
    color2: '#ffb395',
    description: '',
    image: '/assets/examples/graffiti.jpg',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick1.jpg',
    title: 'Graffiti',
  }
  const exampleInfluencer = {
    animation:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-radial-zoom-01.webp',
    color1: '#ffffff',
    color2: '#ffb6a6',
    description: '',
    image: '/assets/examples/influencer.jpg',
    mask: '',
    opacity: 100,
    rarity: 5,
    texture:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines1.jpg',
    title: 'Influencer',
  }

  const maxSizeHero = Math.max(220, Math.min(380, size.width / 3))
  const maxSizeVideo = Math.max(400, Math.min(500, size.width / 2))
  const maxSizeList = Math.max(240, Math.min(340, size.width / 3.5))

  return (
    <>
      <Head>
        <title key="title">Become a creator {titleString}</title>
      </Head>
      <Box
        sx={{
          color: 'common.white',
          mb: 2,
          backgroundImage: 'url(/assets/banner-1240x642-colors.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '100%',
          height: { xs: '360px', sm: '400px' },
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  component="h1"
                  variant={size.width < 600 ? 'h4' : 'h3'}
                  color="textPrimary"
                  gutterBottom
                  sx={{
                    textShadow: '1.5px 1.5px #000',
                    maxWidth: { xs: 'unset', md: '500px' },
                  }}
                >
                  {headerTitle}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box sx={{ mr: 2 }}>
                <CardItem
                  maxHeight={maxSizeHero}
                  maxWidth={maxSizeHero}
                  {...exampleSinger}
                  shadow={true}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: 'url(/assets/svg_triangles_background.svg)',
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'repeat-x',
        }}
      >
        <Container sx={{ py: 8, maxWidth: '640px !important' }}>
          <SectionTitle>{mainTitle}</SectionTitle>
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ my: 4 }}
            dangerouslySetInnerHTML={{ __html: mainText }}
          />
          <Typography
            variant="h5"
            align="center"
            color="primary"
            sx={{ mt: 4, fontWeight: 'bold' }}
            dangerouslySetInnerHTML={{ __html: mainBottom }}
          />
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'rgba(0,0,0,0.25)' }}>
        <Container maxWidth="md" sx={{ py: 8 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box sx={{ mr: 2, mb: { xs: 4, md: 0 } }}>
                <CardItem
                  maxHeight={maxSizeHero}
                  maxWidth={maxSizeHero}
                  {...exampleGraffiti}
                  shadow={true}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <SectionTitle>{creationsTitle}</SectionTitle>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ my: 4 }}
                dangerouslySetInnerHTML={{ __html: creationsText }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: { md: 4 } }}>
            {creationItems.map((item, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{ width: '100%', mb: 3 }}
                    >
                      <Box
                        sx={{
                          background:
                            'linear-gradient(135deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                          mask: `url(${item.image}) center/contain`,
                          width: '80px',
                          height: '80px',
                        }}
                      />
                    </Box>
                    <Typography variant="h6" color="textPrimary" sx={{ mb: 1 }}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Container sx={{ pt: 8, pb: 4 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <VideoItem
            maxHeight={maxSizeVideo}
            maxWidth={maxSizeVideo}
            {...willok}
            shadow={true}
            controls={true}
          />
        </Box>
      </Container>

      <Container sx={{ pt: 8, pb: 4 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <SectionTitle>{aboutTitle}</SectionTitle>
            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
              sx={{ mt: 2, mb: 4 }}
              dangerouslySetInnerHTML={{ __html: aboutText }}
            />
            <LinkContainer href="/about">
              <Typography
                variant="h6"
                color="primary"
                align="center"
                sx={{ mb: 4, textDecoration: 'underline' }}
                dangerouslySetInnerHTML={{ __html: aboutLink }}
              />
            </LinkContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {aboutItems.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        sx={{ mb: 1 }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          backgroundImage: 'url(/assets/svg_triangles_background.svg)',
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'repeat-x',
        }}
      >
        <Container sx={{ pb: 8, pt: 4 }}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardItem
                maxHeight={maxSizeList}
                maxWidth={maxSizeList}
                {...exampleInfluencer}
                shadow={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardItem
                maxHeight={maxSizeList}
                maxWidth={maxSizeList}
                {...exampleSculpture}
                shadow={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardItem
                maxHeight={maxSizeList}
                maxWidth={maxSizeList}
                {...exampleTree}
                shadow={true}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'rgba(0,0,0,0.25)' }}>
        <Container sx={{ py: 8, maxWidth: '640px !important' }}>
          <SectionTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HeartIcon
              fontSize="large"
              sx={{
                animation: 'heart-pulse 1.0s infinite ease-out',
                color: pink[500],
                mr: 2,
              }}
            />
            {supportTitle}
          </SectionTitle>
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ my: 4 }}
            dangerouslySetInnerHTML={{ __html: supportText }}
          />
        </Container>
      </Box>

      <Container sx={{ py: 8, maxWidth: '640px !important' }}>
        <SectionTitle>{startTitle}</SectionTitle>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ my: 4 }}
          dangerouslySetInnerHTML={{ __html: startText }}
        />
        <ApplyContainer />
      </Container>
    </>
  )
}

export default CreatorsPage
