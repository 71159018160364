import { Typography } from '@mui/material'
import React from 'react'

const SectionTitle = (props) => {
  const {
    children,
    align = 'center',
    variant = 'h4',
    component = 'h2',
    sx = {},
  } = props
  return (
    <Typography
      component={component}
      variant={variant}
      align={align}
      color="textPrimary"
      gutterBottom
      sx={sx}
    >
      {children}
    </Typography>
  )
}

export default SectionTitle
